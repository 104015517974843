$naviHeight: 96px;
$logoNavOffset: 109px;

// @import url('https://fonts.cdnfonts.com/css/alliance-no1');
// @import url('https://fonts.cdnfonts.com/css/uber-move-text');

:root {
  --brand-blue-1: #002FA7;
  --brand-blue-2: #0077ff;
  --brand-cyan-1: #33b1ff;
  --black-100: rgba(0, 0, 0, 1);
  --black-80: rgba(0, 0, 0, 0.8);
  --black-60: rgba(0, 0, 0, 0.6);
  --black-40: rgba(0, 0, 0, 0.4);
  --black-20: rgba(0, 0, 0, 0.2);
  --white-100: rgba(255, 255, 255, 1);
  --white-80: rgba(255, 255, 255, 0.8);
  --white-60: rgba(255, 255, 255, 0.6);
  --white-40: rgba(255, 255, 255, 0.4);
  --white-20: rgba(255, 255, 255, 0.2);
  --background-base: #121212;
  --background-base-80: #121212d0;
  --color-button-secondary-selected-bg: rgba(177, 186, 196, 0.08);
  --color-button-secondary-hover-bg: rgba(177, 186, 196, 0.12);
  --color-button-secondary-active-bg: rgba(177, 186, 196, 0.2);
  --color-background-review: #1e2124;
  --color-text-body: #e3e3e3;
  --color-text-body-unselected: #b9b9b9;
  --headline-h1-size: 64px;
  --headline-h3-size: 24px;
  --headline-h1-weight: 300;
  --headline-h1-line-height: 80px;
  --headline-h3-line-height: 30px;
  --headline-h4-line-height: 32px;
  --font-size-emphasize: 16px;
  --line-height-emphasize: 24px;
}

html {
  font-family: 'Uber Move Text', 'Helvetica Neue', Arial, sans-serif;
  font-style: normal;
}

h1 {
  font-size: var(--headline-h1-size);
  font-family: 'Alliance No.2',system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  color: var(--white-100);
  line-height: var(--headline-h1-line-height);
  font-weight: var(--headline-h1-weight);
  font-display: swap;
}

h2 {
  font-family: 'Alliance No 2 Regular';
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  font-display: swap;
}

//主标题文字
.proposition {
  height: 52px;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 12px 0 16px 0;
  font-kerning: none;//This prevents the characters from shifting slightly when text is split/reverted.
}

h3 {
  font-family: 'Alliance No 2 Regular',system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  color: var(--white-100);
  font-size: var(--headline-h3-size);
  line-height: var(--headline-h3-line-height);
  // font-size: var(--headline-h3-size);
  font-weight: 400;
  font-display: swap;
}

h4 {
  font-family: 'Uber Move Text', system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 16px;
  color: var(--color-text-body);
  line-height: var(--headline-h4-line-height);
  font-weight: 500;
  font-display: swap;
} 

#business {
  font-size: 20px;
  color: #E0E0E0;
  font-weight: 400;
}

.projTitle {
  color: var(--brand-cyan-1);
}

p {
  font-family: 'Uber Move Text', system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  // font-size: 16px;
  font-size: var(--font-size-emphasize);
  line-height: var(--line-height-emphasize);
  color: var(--color-text-body);
  font-weight: 300;
  // line-height: 26px;
  font-display: swap;
}

.methodContent {
  font-family: 'Uber Move Text', system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 16px;
  color: var(--color-text-body);
  font-weight: 300;
  line-height: 22px;
  font-display: swap;
  padding-left: 24px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 6px;
}

#review {
  h2 {
    position: sticky;
    top: 0;
    padding-left: 24px;
    color: white;
    mix-blend-mode: difference;
    height: $naviHeight;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(255, 255, 255, 0);
    // backdrop-filter: blur(10px);
  }

  h3 {
    margin-top: 32px;
    position: relative;
    padding-left: 12px;
    height: 64px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  #content>.p {
    position: block;
    color: var(--color-text-body);
    font-size: var(--font-size-emphasize);
    line-height: var(--line-height-emphasize);
  }
  
  .name {
    color: var(--color-text-body);
    font-weight: 500;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .title {
    color: var(--color-text-body);
    font-weight: 400;
    margin-bottom: 8px !important;
  }

  .org {
    color: var(--color-text-body);
    font-weight: 400;
    margin-bottom: 8px !important;
  }

  .relationship {
    color: var(--color-text-body-unselected) !important;
    font-weight: 400;
  }

}

.anchor>p {
  line-height: 50px;
}

footer p {
  color: var(--white-60);
  font-size: 14px;
}


.textBox {

  h3 {
    font-size: var(--headline-h3-size);
    line-height: var(--headline-h3-line-height);
    color: var(--white-100);
    //capitalize the first letter words in h3, excpet the articles
  }

  .challenge {
    margin-bottom: 8px;
    color: var(--color-text-body);
    display: inline-block;
    font-size: var(--font-size-emphasize);
    line-height: var(--line-height-emphasize);
  }

  .solution {
    margin-bottom: 16px;
    font-size: var(--font-size-emphasize);
    line-height: var(--line-height-emphasize);
  }

  a {
    color: var(--color-text-body);

    &:hover {
      color: var(--white-80);
    }
  }
}

p.subtitle {
  font-family: 'Uber Move Text', 'Helvetica Neue', Arial, sans-serif;
  color: var(--color-text-body);
  font-weight: 500;
  margin-bottom: 2px;
}

.subtitleNoPadding {
  font-family: 'Uber Move Text', 'Helvetica Neue', Arial, sans-serif;
  color: var(--color-text-body);
  font-weight: 500;
  margin-bottom: 2px;
  display: inline-block;
}

#titleBox {
  height: fit-content;

  h3 {
    color: #ffffff;
    line-height: var(--headline-h3-line-height);
  }
}

.chipContent {
  padding-left: 24px;
}

.timeLocation {
  position: sticky;
  top: $logoNavOffset;
  line-height: 32px;
  z-index: -1;
}

@media (max-width: 1440px) {
  :root {
    --headline-h1-size: 64px;
    --headline-h1-line-height: 80px;
  }
}

@media (max-width: 1366px) {
  :root {
    --headline-h1-size: 56px;
    --headline-h1-line-height: 72px;
  }
}

@media (max-width: 1060px) {
  :root {
    --headline-h1-size: 48px;
    --headline-h1-line-height: 64px;
    --headline-h3-size: 20px;
    --line-height-emphasize: 20px;
  }
}

@media (max-width: 900px) {
  :root {
    --headline-h1-size: 32px;
    --headline-h1-weight: 600;
    --headline-h1-line-height: 48px;
    --headline-h3-size: 18px;
    --headline-h3-line-height: 28px;
    --headline-h4-line-height: 20px;
  }
}

@media (min-width: 1920px) {
  :root {
    --font-size-emphasize: 18px;
    --line-height-emphasize: 28px;
  }

  .secondaryText{
    row-gap: 16px;
  }
}