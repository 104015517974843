$headerWidth: 240px;
$logoNavOffset: 109px;
$naviHeight: 96px;
$footerHeight: 64px;

:root {
    --brand-blue-1: #002FA7;
    --brand-blue-2: #0077ff;
    --brand-cyan-1: #33b1ff;
    --black-100: rgba(0, 0, 0, 1);
    --black-80: rgba(0, 0, 0, 0.8);
    --black-60: rgba(0, 0, 0, 0.6);
    --black-40: rgba(0, 0, 0, 0.4);
    --black-20: rgba(0, 0, 0, 0.2);
    --white-100: rgba(255, 255, 255, 1);
    --white-80: rgba(255, 255, 255, 0.8);
    --white-60: rgba(255, 255, 255, 0.6);
    --white-40: rgba(255, 255, 255, 0.4);
    --white-20: rgba(255, 255, 255, 0.2);
    --background-base: #121212;
    --background-base-80: #121212d0;
    --color-button-secondary-selected-bg: rgba(177, 186, 196, 0.08);
    --color-button-secondary-hover-bg: rgba(177, 186, 196, 0.12);
    --color-button-secondary-active-bg: rgba(177, 186, 196, 0.2);
    --color-background-review: #1e2124;
    --color-text-body:#e3e3e3;
    --color-text-body-unselected:#b9b9b9;
    --headline-h1-size: 64px;
    --headline-h1-line-height: 96px;
  }

footer {
    width: calc(100% - #{$headerWidth});
}

#content {
    // margin-top: $naviHeight;
    padding: 0 80px 0 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // scroll-snap-type: y mandatory;

    .p {
        // scroll-snap-align: start;
        color: var(--white-100);
        height: calc(100vh - #{$naviHeight});
    }

    #christine {
        height: calc(100vh - #{$naviHeight} - #{$footerHeight});
    }
}

.textBox {
    position: sticky;
    // height: calc(100vh - #{$logoNavOffset});
    top: $logoNavOffset;
}

.picBox {
    padding: 0px;
    gap: 32px;
}

#canvas {
    display: grid;
    grid-template-columns: 240px 0 repeat(5, 1fr) 64px;
    grid-template-areas:
        "sidebar . home home home home home home"
        "sidebar . work work work work work work"
        "sidebar . review review review review review review"
        "sidebar . footer footer footer footer footer footer";
    grid-gap: 0;
}

footer {
    grid-area: footer;
    display: grid;
    grid-template-columns: 0 repeat(5, 1fr) 64px;
    grid-template-rows: 1fr;
    grid-template-areas:
    ". footnote footnote . copyright copyright .";
}

#footnote {
    grid-area: footnote;
    position: absolute;
    height: 100%;
    width: 100%;
    padding-left: 24px;
}

#copyright {
    grid-area: copyright;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-right: 16px;
}

#home {
    grid-area: home;
    display: grid;
    grid-template-columns: 0 repeat(5, 1fr) 64px;
    grid-template-rows: 1fr;
    grid-template-areas:
        ". titleBox titleBox titleBox titleBox titleBox .";
}

#titleBox {
    grid-area: titleBox;
}

#review {
    grid-area: review;
    display: grid;
    grid-template-columns: 0 repeat(5, 1fr) 64px;
    grid-template-rows: 1fr;
    grid-template-areas:
        ". reviewTitle reviewTitle reviewTitle reviewTitle reviewTitle ."
        ". navTree content content content identity .";
}

#work {
    grid-area: work;
    display: grid;
    grid-template-columns: 0 repeat(5, 1fr) 64px;
    grid-template-areas:
        ". logoView logoView logoView logoView logoView logoView"
        ". allProject allProject allProject allProject allProject allProject";
}

#logoView {
    grid-area: logoView;
    display: grid;
    grid-template-columns: 0 repeat(5, 1fr) 64px;
    grid-template-areas:
        ". logoContainer logoContainer logoContainer logoContainer logoContainer .";
}

.projectItem {
    display: grid;
    grid-template-columns: 0 repeat(5, 1fr) 64px;
    grid-template-areas:
        ". textBox textBox picBox picBox picBox .";
}

.secondaryText {
    margin-bottom: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
        "firstLeft firstRight"
        "second second"
        "third third";
    row-gap: 12px;
}

#logoContainer {
    grid-area: logoContainer;
}

#allProject {
    grid-area: allProject;
}

#sidebar {
    grid-area: sidebar;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
}

.textBox {
    grid-area: textBox;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.picBox {
    grid-area: picBox;
}

.firstLeft {
    grid-area: firstLeft;
}

.firstRight {
    grid-area: firstRight;
    padding-right: 40px;
}

.second {
    grid-area: second;
    padding-right: 16px;
}

.secondLeft {
    grid-area: secondLeft;
}

.secondRight {
    grid-area: secondRight;
}

.third {
    grid-area: third;
    padding-right: 40px;
}



//gridline 不应该自己创建一个 layout，而应该穿插于其他 layout 中
// #gridLine {
//     grid-row-start: 1;
//     grid-row-end: 3;
//     grid-column-start: 1;
//     grid-column-end: 2;
//     display: grid;
//     grid-template-columns: 0 repeat(5, 1fr) 64px;
//     grid-template-rows: auto;
//     grid-template-areas:
//     "lineFirst lineSecond lineThird lineFourth lineFifth lineLast .";
// }

#lineFirst {
    grid-row-start: 1;
    grid-row-end: 4;
    grid-column-start: 1;
    grid-column-end: 2;
}

#lineSecond {
    grid-row-start: 1;
    grid-row-end: 4;
    grid-column-start: 2;
    grid-column-end: 3;
}

#lineThird {
    grid-row-start: 1;
    grid-row-end: 4;
    grid-column-start: 3;
    grid-column-end: 4;
}

#lineFourth {
    grid-row-start: 1;
    grid-row-end: 4;
    grid-column-start: 4;
    grid-column-end: 5;
}

#lineFifth {
    grid-row-start: 1;
    grid-row-end: 4;
    grid-column-start: 5;
    grid-column-end: 6;
}

#lineSixth {
    grid-row-start: 1;
    grid-row-end: 4;
    grid-column-start: 6;
    grid-column-end: 7;
}

#lineLast {
    grid-row-start: 1;
    grid-row-end: 4;
    grid-column-start: 7;
    grid-column-end: 8;
}

#reviewTitle {
    grid-area: reviewTitle;
}

#navTree {
    grid-area: navTree;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 8px;
}

#content {
    grid-area: content;
}

#identity {
    grid-area: identity;
}

//media query, when screen width is larger than 2460px
@media only screen and (min-width: 2500px) {

    .projectItem {
        display: grid;
        grid-template-columns: 0 repeat(5, 1fr) 64px;
        grid-template-areas:
            ". textBox picBox picBox picBox picBox .";
    }

    .secondaryText {
        grid-template-areas:
            "firstLeft firstLeft"
            "firstRight firstRight"
            "second second"
            "third third";
        row-gap: 16px;
    }

    #review {
        grid-area: review;
        display: grid;
        grid-template-columns: 0 repeat(5, 1fr) 64px;
        grid-template-rows: 1fr;
        grid-template-areas:
            ". reviewTitle reviewTitle reviewTitle reviewTitle reviewTitle ."
            ". navTree content content identity . .";
    }
}


@media only screen and (max-width: 1440px) {

    #canvas {
        display: grid;
        grid-template-columns: 240px 0 repeat(4, 1fr) 64px;
        grid-template-areas:
            "sidebar . home home home home home"
            "sidebar . work work work work work"
            "sidebar . review review review review review"
            "sidebar . footer footer footer footer footer";
        grid-gap: 0;
    }

    #home {
        grid-area: home;
        display: grid;
        grid-template-columns: 0 repeat(4, 1fr) 64px;
        grid-template-rows: 1fr;
        grid-template-areas:
            ". titleBox titleBox titleBox titleBox .";
    }

    #review {
        grid-area: review;
        display: grid;
        grid-template-columns: 0 repeat(4, 1fr) 64px;
        grid-template-rows: 1fr;
        grid-template-areas:
            ". reviewTitle reviewTitle reviewTitle reviewTitle ."
            ". navTree content content identity .";
    }

    #work {
        grid-area: work;
        display: grid;
        grid-template-columns: 0 repeat(4, 1fr) 64px;
        grid-template-areas:
            ". logoView logoView logoView logoView logoView"
            ". allProject allProject allProject allProject allProject";
    }

    #logoView {
        grid-area: logoView;
        display: grid;
        grid-template-columns: 0 repeat(4, 1fr) 64px;
        grid-template-areas:
            ". logoContainer logoContainer logoContainer logoContainer .";
    }

    footer {
        grid-area: footer;
        display: grid;
        grid-template-columns: 0 repeat(4, 1fr) 64px;
        grid-template-rows: 1fr;
        grid-template-areas:
        ". footnote footnote copyright copyright .";
    }

    .projectItem {
        display: grid;
        grid-template-columns: 0 repeat(4, 1fr) 64px;
        grid-template-areas:
            ". textBox textBox picBox picBox .";
    }

    .secondaryText {
        margin-bottom: 24px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            "firstLeft firstRight"
            "second second"
            "third third";
        row-gap: 16px;
    }
}

@media only screen and (max-width: 1366px) {
    #canvas {
        display: grid;
        grid-template-columns: 64px repeat(4, 1fr) 64px;
        grid-template-areas:
            ". sidebar sidebar sidebar sidebar sidebar"
            ". home home home home home"
            ". work work work work work"
            ". review review review review review"
            ". footer footer footer footer footer";
        grid-gap: 0;
    }

    footer {
        width: calc(100% - 64px);
    }
}

@media only screen and (max-width: 1060px) {

    #canvas {
        display: grid;
        grid-template-columns: 64px repeat(2, 1fr) 64px;
        grid-template-areas:
            ". home home home"
            ". work work work"
            ". review review review"
            ". footer footer footer";
        grid-gap: 0;
    }

    #home {
        grid-area: home;
        display: grid;
        grid-template-columns: 0 repeat(2, 1fr) 64px;
        grid-template-rows: 1fr;
        grid-template-areas:
            ". titleBox titleBox .";
    }

    #review {
        grid-area: review;
        display: grid;
        grid-template-columns: 0 repeat(2, 1fr) 64px;
        grid-template-rows: 1fr;
        grid-template-areas:
            ". reviewTitle reviewTitle ."
            ". identity content .";
    }

    #navTree {
        display: none;
    }

    #work {
        grid-area: work;
        display: grid;
        grid-template-columns: 0 repeat(2, 1fr) 64px;
        grid-template-areas:
            ". logoView logoView logoView"
            ". allProject allProject allProject";
    }

    footer {
        grid-area: footer;
        display: grid;
        grid-template-columns: 0 repeat(2, 1fr) 64px;
        grid-template-rows: 1fr;
        grid-template-areas:
        ". footnote copyright .";
    }

    #logoView {
        grid-area: logoView;
        display: grid;
        grid-template-columns: 0 repeat(2, 1fr) 64px;
        grid-template-areas:
            ". logoContainer logoContainer .";
    }

    .projectItem {
        display: grid;
        grid-template-columns: 0 repeat(2, 1fr) 64px;
        grid-template-areas:
            ". textBox picBox .";
    }

    .secondaryText {
        grid-template-areas:
            "firstLeft firstLeft"
            "firstRight firstRight"
            "second second"
            "third third";
    }

}

@media only screen and (max-width: 900px) {
    .textBox {
        position: relative !important;
        height: fit-content !important;
        top: 0;
    }

    .picBox {
        padding: 0 24px 0 24px;
        gap: 24px;
    }

    #canvas {
        display: grid;
        grid-template-columns: 12px 1fr 12px;
        grid-template-areas:
            "home home home"
            "work work work"
            "review review review"
            "footer footer footer";
        grid-gap: 0;
    }
    
    #home {
        grid-area: home;
        display: grid;
        grid-template-columns: 12px 1fr 12px;
        grid-template-rows: 1fr;
        grid-template-areas:
            ". titleBox .";
    }


    #review {
        grid-area: review;
        display: grid;
        grid-template-columns: 12px 1fr 12px;
        grid-template-rows: 1fr;
        grid-template-areas:
            ". reviewTitle ."
            ". identity ."
            ". content ."
        ;
    }

    #navTree {
        display: none;
    }

    #work {
        grid-area: work;
        display: grid;
        grid-template-columns: 12px 1fr 12px;
        grid-template-areas:
            "logoView logoView logoView"
            ". allProject allProject";
    }

    #footer {
        width: 100%;
        grid-area: footer;
        display: grid;
        grid-template-columns: 12px 1fr 12px;
        grid-template-rows: 1fr;
        grid-template-areas:
        ". footnote .";
    }

    #logoView {
        grid-area: logoView;
        display: grid;
        grid-template-columns: 12px 1fr 12px;
        grid-template-areas:
            ". logoContainer .";
    }

    #leftArrow {
        display: none;
    }

    #rightArrow {
        display: none;
    }

    .projectItem {
        display: grid;
        grid-template-columns: 0 1fr 12px;
        grid-template-areas:
            ". textBox ."
            ". picBox .";
    }

    .secondaryText {
        display: none;
    }

    #content {
        .p {
            height: auto;
            margin-top: 64px;
        }

        #christine {
            height: auto;
            margin-bottom: 64px;
        }
    }

    #identity {
        display: none;
    }
}


.edgeLine {
    position: relative;
    border-right: 1px solid rgba(255, 255, 255, 0.10);
    z-index: 11;
    pointer-events: none;
}

.gridLine {
    position: relative;
    border-right: 1px dashed rgba(255, 255, 255, 0.15);
    z-index: 8;
    pointer-events: none;
}