$headerWidth: 240px;
// $naviHeight: 96px;
// $logoNavOffset: 136px;
$logoNavOffset: 109px;
$footerHeight: 64px;

.Typewriter {
  display: inline-block;
}

.dynamicText {
  background-color: var(--brand-blue-1);
}

:root {
  --naviHeight: 96px;
  --logoNavOffset: 109px;
  --brand-blue-1: #002FA7;
  --brand-blue-2: #0077ff;
  --brand-cyan-1: #33b1ff;
  --black-100: rgba(0, 0, 0, 1);
  --black-80: rgba(0, 0, 0, 0.8);
  --black-60: rgba(0, 0, 0, 0.6);
  --black-40: rgba(0, 0, 0, 0.4);
  --black-20: rgba(0, 0, 0, 0.2);
  --white-100: rgba(255, 255, 255, 1);
  --white-80: rgba(255, 255, 255, 0.8);
  --white-60: rgba(255, 255, 255, 0.6);
  --white-40: rgba(255, 255, 255, 0.4);
  --white-20: rgba(255, 255, 255, 0.2);
  --transp-fill-hover: #232323;
  --transp-fill-active: #313131;
  --background-base: #121212;
  --background-base-80: #121212d0;
  --color-button-secondary-selected-bg: rgba(177, 186, 196, 0.08);
  --color-button-secondary-hover-bg: rgba(177, 186, 196, 0.12);
  --color-button-secondary-active-bg: rgba(177, 186, 196, 0.2);
  --color-background-review: #1e2124;
  --color-text-body: #e3e3e3;
  --color-text-body-unselected: #b9b9b9;
  --headline-h1-size: 64px;
  --headline-h1-line-height: 96px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth; 
  scrollbar-width: none; /* firefox */
}

body::-webkit-scrollbar{
  display: none; /* Chrome, Safari */
}

body {
  background-color: var(--background-base);
  width: auto !important;
  /* Firefox will set width as auto */
  -ms-overflow-style: none;
  /* disable scroll bar for edge and ie */
}

.collapse {
  //hide the collapse element
  display: none;
}

#back-to-top-button {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 64px;
  height: 64px;
  z-index: 100;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: transparent;

  img {
    width: 40%;
  }

  &:hover {
    background-color: var(--transp-fill-hover);

    img {
      width: 50%;
      transition: width 0.3s ease-in-out;
    }
  }

  &:active {
    background-color: var(--transp-fill-active);

    img {
      width: 10%;
    }
  }



}

.fade-in {
  transition: visibility 0s linear 0s, opacity 0.3s linear;
  visibility: visible;
  opacity: 1;
}

.fade-out {
  transition: visibility 0.1s linear 0s, opacity 0.1s linear;
  visibility: hidden;
  opacity: 0;
}

header {
  position: fixed;
  width: $headerWidth;
  height: 100vh;
  // background-color: var(--background-base);
  background-color: var(--black-100);
  overflow: hidden;

  #hamburger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: var(--white-100);
    position: fixed;
    top: 16px;
    left: 0;
    width: 64px;
    height: 64px;
    z-index: 1;
    transition: transform 0.5s ease-in-out;
  }

  #hamburger:hover {

    background: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgba(148, 187, 233, 1) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

  }

  .inactive {
    display: none;
  }

  #pageNav {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 4px;
    cursor: pointer;

    .navButton {
      color: var(--color-text-body-unselected);
      width: 100%;
      padding: 0 0 0 40px;
      height: 52px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: default;

      &::after {
        content: "";
        position: absolute;
        right: 0;
        width: 2px;
        height: 0;
        border-radius: 6px;
        -webkit-transition: height .15s ease-in;
        -moz-transition: height .15s ease-in;
        -o-transition: height .15s ease-in;
        transition: height .15s ease-in;
        background-color: white;
      }

      &:hover::after {
        height: 52px;
      }

      &.active {
        color: var(--white-100);
        font-weight: 500;
      }

      &.active::after {
        content: "";
        position: absolute;
        right: 0;
        width: 2px;
        height: 52px;
        background-color: white;
      }

    }

    //a is for the links (Find Me)
    a:link,
    a:visited {
      color: #b3b3b3;
      width: 100%;
      padding: 16px 0 16px 40px;
      text-decoration: none;
      display: inline-block;
    }

    a:hover {
      text-decoration: none;
      color: var(--color-text-body);
    }

    a.hyperLink:hover {
      text-decoration: underline;
    }

    a:active {
      color: white;
      text-decoration: none;
    }
  }
}
.picBox > [data-rmiz] { width: 100%; }

@media screen and (max-width: 1440px) {

  .picBox {
    padding: 0 16px 0 16px !important;
  }

  .textBox {
    .mainText, .subtitle, .chipContent, .methodContent {
      padding-left: 20px !important;
    }
  }
}


@media screen and (max-width: 1366px) {
  header {
    width: 64px;
  }

  #home-video-wrapper {
    width: 100% !important;
  }

  .picBox {
    padding: 0 16px 0 16px !important;
  }

  .textBox {
    .mainText, .subtitle, .chipContent, .methodContent {
      padding-left: 18px !important;
    }
  }
}

@media screen and (max-width: 900px) {
  :root {
    --naviHeight: 64px;
  }

  header {
    display: none !important;
  }

  #CTA {
    font-size: 16px;
    height: 48px !important;
    padding: 0 16px 0 16px !important;
  }

  #titleBox {
    padding-left: 24px !important;
    padding-right: 44px !important;
    gap: 24px !important;

    #slogan {
      margin-bottom: 0 !important;
    }
  }

  .textBox {
    padding-right: 12px !important;
    width: 100%;
  }

  .mainText {
    padding-left: 16px !important;
    width: 100%;

    h4 {
      // position: sticky;
      top: 90px;
    }

    h3 {
      height: auto;
      margin: 12px 0 12px 0;
    }
  }

  .picBox {
    margin-top: 12px !important;
    padding: 0 16px 0 16px !important;
  }


  #allProject {
    padding: 32px 0 0 0 !important;

    .projectItem {
      margin-bottom: 64px !important;
    }
  }

  #content {
    padding: 0 16px 0 12px !important;

    .p {
      margin-top: 32px;
      font-weight: 300;
    }
  }

  // #lee {
  //   scroll-margin-top: 1000px;
  // }

  #logoView {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 100%, rgba(0, 0, 0, 0.7) 100%) !important;
  }

  #leftArrow {
    left: 12px !important;
    width: var(--naviHeight) !important;
    z-index: 20;
  }

  #rightArrow {
    right: 12px !important;
    width: var(--naviHeight) !important;
    z-index: 20;
  }

  #back-to-top-button {
    right: 12px;
  }
}


#logoContainer {
  width: 100%;
  // height: $naviHeight;
  overflow: hidden;
  z-index: 12;

  /* display */
  #logoNav {
    height: var(--naviHeight);
    display: flex;
  }


  .logoWrap {
    height: 100%;
    width: calc(100% / 5);
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .orgLogo {
      height: 32px;
    }

    .orgLogo>path {
      fill: var(--white-40);
    }

    &:hover {
      .orgLogo>path {
        fill: var(--white-80);
        transition: 0.2s;
      }
    }

    &:active {
      .orgLogo>path {
        fill: var(--white-100);
      }
    }

    // &:focus {
    //   border: 2px dashed var(--white-100);
    // }
  }

  .active {
    .orgLogo>path {
      fill: var(--white-100);
    }
  }

}

#siteName {
  height: var(--naviHeight);
  display: flex;
  align-items: center;
  padding-left: 40px;
  cursor: pointer;

  &:hover {
    h3 {
      background: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgba(148, 187, 233, 1) 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

#titleBox {
  position: absolute;
  top: 36px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 0px 24px;
  gap: 16px;
  z-index: 12;

  #slogan {
    margin-bottom: 8px;
  }
}

/* 以下是 Home section */

#home {
  // position: relative;
  height: calc(100vh - var(--naviHeight));
  //set the min height to 600px
  min-height: 400px;
  overflow: auto;
  // width: 100%;
  // background: no-repeat center url("~/public/assets/page_bg.png");
  // background-size: cover;
}

#home-video-wrapper {
  /* Positioning the container in the grid layout
  and make it occupy the full grid */
  grid-column-start: 1;
  grid-column-end: 8;
  /* Telling our absolute positioned video to 
  be relative to this element */
  position: absolute;
  right: 0;
  width: calc(100% - 240px + 64px);
  height: 100vh;
  overflow: hidden;
  /* Centering the container's content vertically 
  and horizontally */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  // transform: translateX(-64px);

  video {
    /** Simulationg background-size: cover */
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

#CTA {
  // border: 1px solid var(--white-100);
  margin-top: 24px;
  border: none;
  font-family: 'Uber Move Text', 'Helvetica Neue', Arial, sans-serif;
  font-size: 18px;
  height: 56px;
  background: var(--black-100);
  color: var(--white-100);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px 0 24px;
  cursor: default;
  transition: 0.3s;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  &:hover {
    background-color: var(--white-100);
    color: var(--black-100);
  }

  &:focus:active {
    background-color: var(--brand-blue-1);
    color: var(--white-100);
  }

  &:focus {
    outline: 2px dashed var(--white-100);
    outline-offset: 2px;
    background: var(--black-100);
    color: var(--white-100);
    transition: none;
  }
}

.dynamicBox {
  width: 100px;
  height: 100px;
  padding: 2px;
  border: 0.5px solid #3C9DE4;
}



/* 以下是 Work section */

#work {
  height: auto;
  // background-color: var(--background-base)
}

#logoView {
  position: sticky;
  position: -webkit-sticky;
  /* Safari */
  top: 0;
  width: 100%;
  height: var(--naviHeight);
  // background: linear-gradient(90deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.35) 100%);
  // background: rgba(0, 0, 0, 0.8) 100%;
  // backdrop-filter: blur(10px);
  z-index: 11;

  &.isOnTop {
    background: var(--background-base-80);
    backdrop-filter: blur(10px);

    .gridLine {
      background: none !important;
      border-right: 1px dashed rgba(255, 255, 255, 0.15) !important;
    }

    .edgeLine {
      background: none !important;
      border-right: 1px solid rgba(255, 255, 255, 0.10) !important;
    }
  }

  #leftArrow,
  #rightArrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 32px;
    width: 32px;
    height: var(--naviHeight);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0;
    background-color: transparent;

    &:disabled {
      background-color: transparent;
      opacity: 0.2;
      cursor: default;
    }

    &:hover:enabled {
      background-color: var(--transp-fill-hover);
    }

    &:active:enabled {
      background-color: var(--transp-fill-active);
    }


    img {
      width: 32px;
    }
  }

  #rightArrow {
    right: 0px;

    img {
      transform: scaleX(-1);
    }
  }
}

#projectContainer {
  border-top-left-radius: 8px;
  grid-area: 2 / 1 / 3 / 7;
  /* 让 frame 位于 logo nav 下层*/
  height: calc(100vh - 96px);
  background-color: #b3b3b3;
  position: relative;
}

#allProject {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  padding: 19px 0 40px 0;
  overflow: visible;
}

.projectItem {
  overflow: visible;
  height: fit-content;
  margin-bottom: 96px;
}

.textBox {
  // padding-right: 40px;
  width: 100%;
  position: -webkit-sticky;
  /* Safari */

  .mainText {
    padding-right: 40px;
    padding-left: 24px;
    width: 100%;
  }

  .textChip {
    margin-bottom: 16px;
    padding-left: 0;
  }

  .subtitle {
    padding-left: 24px;
    line-height: 24px;
    // -webkit-box-shadow: inset 1px 0px white;
    // -moz-box-shadow: inset 1px 0px white;
    // box-shadow: inset 1px 0px white;
  }
  .methodItem {
    height: 20px;
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 16px !important;
    padding-right: 8px;
    padding-left: 8px;
    margin-right: 6px;
    border-radius: 3px;
    font-weight: 400;
    white-space: nowrap;
  }
  // .line {
  //   position: relative;
  // }

  // .line-mask {
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-color: var(--background-base);
  //   opacity: 0.8;
  //   z-index: 2;
  // }
}

.picBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: fit-content;
  margin-top: 40px;
  z-index: 9;
  padding: 0 16px 0 16px;

  img,
  video {
    border-radius: 4px;
  }

}

.anchor::before {
  background-color: aqua;
  content: "";
  display: block;
  height: 140px;
  margin-top: -140px;
  visibility: hidden;
}

/* 以下是 Approach section */

#approach {
  position: relative;
  background-color: grey;
  height: 100vh;
  width: 100%;
}

/* 以下是 About section */

#about {
  position: relative;
  background-color: rgb(78, 78, 78);
  height: 100vh;
  width: 100%;
}

/* 以下是 Review section */

#review {
  background-color: var(--color-background-review);
  height: fit-content;
  margin-bottom: $footerHeight;

  #navTree {
    // margin-top: $naviHeight;
    width: 100%;
    height: fit-content;
    position: sticky;
    position: -webkit-sticky;
    top: var(--naviHeight);
    z-index: 15; //避免被 grid line 遮住
  }

  .navLink {
    margin: 0 12px 0 12px;
    padding: 0 8px 0 12px;
    border-radius: 6px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: var(--color-text-body-unselected);
    cursor: default;

    &:hover {
      background-color: var(--color-button-secondary-hover-bg);
      color: var(--color-text-body);
    }

    &:active {
      background-color: var(--color-button-secondary-active-bg);
      color: var(--color-text-body);
    }
  }

  .selected {
    background-color: var(--color-button-secondary-selected-bg);
    color: var(--color-text-body);
    font-weight: 500;
    // -webkit-box-shadow: inset -2px 0px white;
    // -moz-box-shadow: inset -2px 0px white;
    // box-shadow: inset -2px 0px white;

    &::after {
      content: "";
      position: absolute;
      width: 3px;
      border-radius: 6px;
      height: 40px;
      background-color: var(--brand-blue-2);
      right: 0;
    }
  }


  #identity {
    position: sticky;
    top: var(--naviHeight);
    // margin: $naviHeight 0 0 0;
    width: auto;
    height: fit-content;

    .identityItem {
      position: absolute;
      height: fit-content;
      visibility: hidden;

      img {
        width: 100%;
        padding: 0 16px 0 16px;
      }

      p {
        margin: 0 24px 0 16px;
        font-size: 16px;
        // font-size: unquote("clamp(0.1rem, 0.13rem + 0.9vw, 1.4rem)");
        line-height: 24px;
      }
    }

    .identityItem.isActive {
      visibility: visible;
    }
  }
}

/* 以下是 Footer */

footer {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: -1;
  height: $footerHeight;
  // padding: 0 80px 0 24px;
  background-color: var(--black-100);
  // display: flex;
  // justify-content: space-between;
  // align-items: center;

  /* 高度需要重写成相对高度 */
  #footnote {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 12px;

    #name {
      color: var(--color-text-body);
      font-weight: 500;
    }

    #footerSlogan {
      color: var(--color-text-body);
      font-weight: 400;
    }
  }

  #copyright {
    color: var(--color-text-body-unselected);
  }

}

.footnoteSmall {
  justify-content: flex-start;
  padding-right: 16px;
}